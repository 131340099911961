.DayPicker {
  display: inline-block;
  width: 100%;
  font-size: 14px;
}

.DayPicker-wrapper {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-bottom: 1rem;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.DayPicker-Months {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.DayPicker-Month {
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  margin-top: 1rem;

  width: 100%;
}

.DayPicker-NavBar {
}

.DayPicker-NavButton {
  position: absolute;
  cursor: pointer;
  top: 1rem;
  right: 1.5rem;
  margin-top: 2px;
  color: #477857;
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
}

.DayPicker-NavButton:hover {
  opacity: 0.8;
}

.DayPicker-NavButton--prev {
  margin-right: 1.5rem;
  background-image: url("./ic-keyboard-arrow-back-black-24-px.svg");
}

.DayPicker-NavButton--next {
  background-image: url("./ic-keyboard-arrow-forward-black-24-px.svg");
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  padding: 0 0.5rem;
  display: table-caption;
  text-align: left;
  color: #477857;
  margin-bottom: 0.5rem;
}

.DayPicker-Caption > div {
  font-size: 1.15rem;
  font-weight: 500;
}

.DayPicker-Weekdays {
  margin-top: 1rem;
  display: table-header-group;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5rem;
  font-size: 0.875em;
  text-align: center;
  color: #8b9898;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  display: table-cell;
  padding: 0.5rem;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  outline: none;
}

.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.5rem;
  text-align: right;
  vertical-align: middle;
  min-width: 1rem;
  font-size: 0.75em;
  cursor: pointer;
  color: #8b9898;
  border-right: 1px solid #eaecec;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: 0.5rem;
}

.DayPicker-TodayButton {
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
  color: #4a90e2;
  font-size: 0.875em;
}

/* Default modifiers */

.DayPicker-Day--today {
  font-weight: 600;
}

.DayPicker-Day--outside {
  cursor: default;
  color: #8b9898;
}

.DayPicker-Day--disabled {
  color: #dce0e0;
  cursor: default;
  /* background-color: #eff1f1; */
}

/* Example modifiers */

.DayPicker-Day--sunday {
  background-color: #f7f8f8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #dce0e0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;
  color: #f0f8ff;
  background-color: #477857;
  border-radius: 0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #477857;
  color: #f0f8ff;
  border-radius: 0;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #477857;

  color: #f0f8ff;
  border-radius: 0;
}

/* DayPickerInput */

.DayPickerInput {
  width: 100%;
}
::placeholder {
  color: black;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: black;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: black;
}

.DayPickerInput input {
  height: 100%;
  border: 0px;
  width: 100%;
  font-size: inherit;
  font-family: inherit;

  border-radius: 0px;
  box-sizing: border-box;
  padding-left: 16px;
}
input:focus {
  outline: none;
}

.DayPickerInput-OverlayWrapper {
  position: relative;
  width: 100%;
}

.DayPickerInput-Overlay {
  left: -1px;
  width: 100%;
  z-index: 9999;
  box-sizing: content-box;
  position: absolute;
  background: white;
  -webkit-box-shadow: 0 3px 8px -2px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 8px -2px rgba(0, 0, 0, 0.3);
  border: solid 1px #d0d0d0;
}
